import React from 'react';
import OutlineArrowhead from '../../images/dashboard/full_arrowhead.png';
import Heart from '../../images/dashboard/reverse_heart.png';
import Score from '../../images/dashboard/tracker_icon.png';

export enum TileProductName {
    HealthEssentials,
    CancerNavigator,
    PhysioNavigator,
    HealthTracker
}

interface Props {
    product: TileProductName;
    title: string;
    actionText?: string;
    action?: Function;
    isFullWidth?: boolean;
}

function DashboardTile(props: Props) {
    function bgColourStyle() {
        switch (props.product) {
            case TileProductName.HealthEssentials:
                return "bg-lime-fg";
            case TileProductName.CancerNavigator:
                return "bg-lime-bg";
            case TileProductName.PhysioNavigator:
                return "bg-lime-navy";
            case TileProductName.HealthTracker:
                return "bg-moss-green";
            default:
                return "bg-lime-green";
        }
    }

    function titleColourStyle() {
        switch (props.product) {
            case TileProductName.HealthEssentials:
                return "text-lime-bg";
            case TileProductName.CancerNavigator:
                return "text-pure-white";
            case TileProductName.PhysioNavigator:
                return "text-pure-white";
            case TileProductName.HealthTracker:
                return "text-sherpa-blue";
            default:
                return "text-black";
        }
    }

    function tileMsg() {
        switch (props.product) {
            case TileProductName.HealthEssentials:
                return (
                    <div>
                        <p className="text-p05 font-medium"><strong className="text-lime-navy">Book a clinician</strong> in as soon as 1 hour
                            or join video consultations/manage upcoming appointments</p>
                    </div>
                );
            case TileProductName.CancerNavigator:
                return (
                    <div>
                        <p className="text-p05 font-medium"><strong className="text-lime-navy">Financial, Emotional and Practical Cancer
                            Support</strong> for you or a loved one</p>
                    </div>
                );
            case TileProductName.PhysioNavigator:
                return (
                    <div>
                        <p></p>
                    </div>
                );
            case TileProductName.HealthTracker:
                return (
                    <div>
                        <p className="text-p05 font-medium"><strong className="text-lime-navy">Track your health</strong> and set
                            goals to improve your health and wellbeing</p>
                    </div>
                );
            default:
                return null;
        }
    }

    function getProductImage() {
        switch (props.product) {
            case TileProductName.HealthEssentials:
                return Heart;
            case TileProductName.CancerNavigator:
                return OutlineArrowhead;
            case TileProductName.PhysioNavigator:
                return OutlineArrowhead;
            case TileProductName.HealthTracker:
                return Score;
            default:
                return OutlineArrowhead;
        }
    }

    return (
        <div
            className={`dashboardTileProduct flex bg-lime-fg-light p-md xl:p-lg gap-md rounded-lg shadow-tile justify-between items-stretch border border-1 border-lime-light h-full`}>
            <div className={`flex ${props.isFullWidth ? 'flex-row' : 'flex-col'} gap-md xl:gap-md w-full`}>
                <div className={`dashboardTileProductTitle ps-1 pe-0 sm:px-4 py-4 w-full ${props.isFullWidth ? 'basis-1/2' : ''}`}>
                    <div className={`relative`}>
                        <h3 onClick={() => props.action!()} className={`${titleColourStyle()} ${bgColourStyle()} m-0 py-4 pl-20 sm:pl-28 font-sans-co font-bold text-h4 sm:text-h3 rounded-xl cursor-pointer`}>
                            <span
                                className={`w-24 h-24 -top-4 -left-4 rounded-full ${bgColourStyle()} absolute  flex items-center justify-center`}>
                                <span className={'z-10 w-14 h-14 sm:w-18 sm:h-18 rounded-full absolute top-5 left-5 sm:top-3 sm:left-3 bg-lime-green'}>
                                </span>
                                <img src={getProductImage()} alt="Icon" className="w-10 h-10 sm:w-12 sm:h-12 z-20"/>
                            </span> {props.title}
                        </h3>
                    </div>
                </div>
                <div
                    className={`dashboardTileProductInfo flex ${props.isFullWidth ? 'basis-1/2' : 'w-full'} gap-md ${props.isFullWidth ? 'flex-col' : 'flex-row'} items-center text-left h-full`}>
                    {props.action && (
                        <div className="text-left flex align-baseline basis-2/6 w-2/6">
                            <button
                                className="navigationButton text-p1 px-lg lg:px-10 py-sm pt-xs w-fit font-sans-co"
                                onClick={() => props.action!()}>
                                {props.actionText}
                            </button>
                        </div>
                    )}
                    <div className="basis-4/6 w-4/6 text-p2">
                        {tileMsg()}
                    </div>
                </div>
            </div>
        </div>
    );


}

export default DashboardTile;