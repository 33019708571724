import React from 'react';
import ReactDOM from 'react-dom/client';
import {AuthProvider} from "react-oidc-context"
import App from './components/App';
import {User} from "oidc-client-ts";
import LimeApi from "./models/limeApi";
import Documents from "./components/Documents";
import Help from "./components/Help";
import Dashboard from "./components/Dashboard";
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import Profile from "./components/Profile";
import {composeUrl} from "./utils/api";
import AccessDenied from "./components/AccessDenied";
import Error from "./components/Error";
import Cancer from "./components/CancerNavigator";
import CashClaim from "./components/CancerNavigator/CashClaim";
import HealthEssentials from "./components/HealthEssentials";
import HealthTracker from "./components/HealthTracker";

let load = async () => {
    try {
        const url = composeUrl('configuration')
        const response = await fetch(url);
        const config = await response.json();

        const oidcConfig = {
            authority: config.auth,
            client_id: "LimeMemberPortal",
            redirect_uri: config.redirectUri,
            response_type: "code",
            scope: `openid profile ${LimeApi.MemberGateway} ${LimeApi.Appointment}`,
            loadUserInfo: true,
            automaticSilentRenew: false,
            onSigninCallback: (_user: any): void => {
                window.history.replaceState(
                    {},
                    document.title,
                    _user.state
                )
            }
        }

        const router = createBrowserRouter([
            {
                errorElement: <Error/>,
                path: "/",
                element: <App/>,
                handle: {
                    crumb: () => "Member Portal"
                },
                children: [
                    {
                        path: "dashboard",
                        element: <Dashboard/>,
                        handle: {
                            crumb: () => "Dashboard",
                        },
                    },
                    {
                        element: <Outlet/>,
                        children: [
                            {
                                path: "healthTracker",
                                element: <HealthTracker/>,
                                handle: {
                                    crumb: () => "Health Tracker",
                                },
                            },
                            {
                                path: "healthTracker",
                                element: <Outlet/>,
                                handle: {
                                    crumb: () => "Health Tracker",
                                },
                                children: [
                                    {
                                        element: <Outlet/>,
                                        children: [

                                        ]
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        element: <Outlet/>,
                        children: [
                            {
                                path: "healthEssentials",
                                element: <HealthEssentials/>,
                                handle: {
                                    crumb: () => "Health Essentials",
                                },
                            },
                            {
                                path: "healthEssentials",
                                element: <Outlet/>,
                                handle: {
                                    crumb: () => "Health Essentials",
                                },
                                children: [
                                    {
                                        element: <Outlet/>,
                                        children: [

                                        ]
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        element: <Outlet/>,
                        children: [
                            {
                                path: "cancer",
                                element: <Cancer/>,
                                handle: {
                                    crumb: () => "Cancer Navigator",
                                },
                            },
                            {
                                path: "cancer",
                                element: <Outlet/>,
                                handle: {
                                    crumb: () => "Cancer Navigator",
                                },
                                children: [
                                    {
                                        element: <Outlet/>,
                                        children: [
                                            {
                                                path: "cashclaim",
                                                element: <CashClaim/>
                                            },
                                        ],
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        path: "documents",
                        element: <Documents/>,
                        handle: {
                            crumb: () => "Documents"
                        }
                    },
                    {
                        path: "help",
                        element: <Help/>,
                        handle: {
                            crumb: () => "Help"
                        }
                    },
                    {
                        path: "profile",
                        element: <Profile/>,
                        handle: {
                            crumb: () => "Profile"
                        }
                    }
                ]
            },
            {
                path: "access-denied",
                element: <AccessDenied/>
            },
            {
                path: "error",
                element: <Error/>
            }
        ]);

        const root = ReactDOM.createRoot(
            document.getElementById('root') as HTMLElement
        );
        root.render(
            <React.StrictMode>
                <AuthProvider {...oidcConfig}>
                    <RouterProvider router={router}/>
                </AuthProvider>
            </React.StrictMode>,
        );

    } catch (e) {
        console.error(e);
    }
}

void load();