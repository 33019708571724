import React from "react";
import { useNavigate } from "react-router-dom";

function ProductInfo(){
    const navigate = useNavigate();

    const navigateToHome = () => navigate("/HealthTracker", {state: {showHealthTrackerHome: true}});
    const navigateToDocs = () => navigate("/documents");

    return (
        <div>
            <span>Product Info</span>
            <div
                className="productAction px-2 mb-2 md:mb-lg text-center col-span-12 md:col-span-3 order-4 md:order-none md:row-start-2 md:h-full">
                <div className="md:h-full">
                    <span
                        onClick={navigateToHome}
                        rel="noreferrer"
                        className="rounded-xl bg-lime-orange p-4 font-medium text-lime-bg items-center
                        space-x-4 cursor-pointer flex justify-center md:justify-between xl:justify-center hover:no-underline h-full text-center"
                    >
                    <h2 className="text-lime-blue text-p0 md:text-2xl md:text-4xl xl:text-h1
                        flex flex-row align-middle md:flex-col productPageAction">
                        <strong className="me-1">Scan Now</strong>
                    </h2>
                </span>
                </div>
            </div>
        </div>
    );
}

export default ProductInfo;