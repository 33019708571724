import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { composeUrl } from "../../utils/api";
import RadioGroup from "../Shared/Radio/RadioGroup";
import StateButton from "../Shared/StateButton/StateButton";

enum GenderAtBirth {
    Male = 1,
    Female = 2
}

enum DiabetesStatus {
    None = 1,
    Type1 = 2,
    Type2 = 3
}

interface CreateScanFormData {
    age: number | undefined;
    gender: GenderAtBirth | undefined;
    height: number | undefined;
    weight: number | undefined;
    diabetesStatus: DiabetesStatus | undefined;
    usesBloodPressureMedication: boolean | undefined;
    isSmoker: boolean | undefined;
}

function Home() {
    const auth = useAuth();
    const navigate = useNavigate()

    const navigateToProductInfo = () => navigate("/HealthTracker", {state: {showHealthTrackerHome: false}});

    const {handleSubmit, formState} = useForm<CreateScanFormData>();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    const [genderAtBirth, setGenderAtBirth] = useState<GenderAtBirth>();
    const [diabetesStatus, setDiabetesStatus] = useState<DiabetesStatus>();
    const [usesBloodPressureMedication, setUsesBloodPressureMedication] = useState<boolean>();
    const [isSmoker, setIsSmoker] = useState<boolean>();
    const [age, setAge] = useState<number>();
    const [height, setHeight] = useState<number>();
    const [weight, setWeight] = useState<number>();

    const handleGenderAtBirth = (selectedOption: string) => {
        const selectedOptionEnum = GenderAtBirth[selectedOption as keyof typeof GenderAtBirth];
        setGenderAtBirth(selectedOptionEnum);
    }

    const handleDiabetesStatus = (selectedOption: string) => {
        const selectedOptionEnum = DiabetesStatus[selectedOption as keyof typeof DiabetesStatus];
        setDiabetesStatus(selectedOptionEnum);
    }

    const handleUsesBloodPressureMedication = (selectedOption: string) => {
        setUsesBloodPressureMedication(selectedOption === "true");
    }

    const handleIsSmoker = (selectedOption: string) => {
        setIsSmoker(selectedOption === "true");
    }

    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseFloat(event.target.value);
        const input = event.target.id;

        if (isNaN(newValue) || newValue <= 0) {
            // Handle invalid or non-positive number input
            return;
        }

        switch (input) {
            case "age":
                setAge(newValue);
                break;
            case "height":
                setHeight(newValue);
                break;
            case "weight":
                setWeight(newValue);
                break;
        }
    };

    const submitButtonDisabled = () => {
        return (age === undefined
            || height === undefined
            || weight === undefined
            || genderAtBirth === undefined
            || diabetesStatus === undefined
            || usesBloodPressureMedication === undefined
            || isSmoker === undefined);
    }

    const onSubmit = (data: CreateScanFormData) => {
        setSubmitLoading(true);

        let createScanFormData: CreateScanFormData = {
            age: age,
            gender: genderAtBirth,
            height: height,
            weight: weight,
            diabetesStatus: diabetesStatus,
            usesBloodPressureMedication: usesBloodPressureMedication,
            isSmoker: isSmoker
        };

        fetch(composeUrl(`v1/healthTracker`),
            {
                headers: [['Authorization', `Bearer ${auth.user?.access_token}`], ['Content-Type', 'application/json']],
                method: 'POST',
                body: JSON.stringify(createScanFormData)
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                return response.json();
            })
            .then(data => {
                if (data.assesmentUrl !== undefined) {
                    // open scan page using returned url in new tab
                    window.open(data.assesmentUrl, '_blank');
                    // return to product info page
                    navigateToProductInfo();
                }
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            })
            .finally(() => {
                setSubmitLoading(false);
            });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-xl">
            <div className="flex flex-row gap-md">
                <p className="font-bold">
                    Enter your age
                </p>
                <div className="text-p1 flex items-center mb-xs">
                    <input
                        className={"border border-solid border-primary-text rounded-md py-sm px-sm w-full max-w-full"}
                        type="number"
                        id="age"
                        name="age"
                        min={1}
                        max={120}
                        onChange={handleNumberChange}
                    />
                </div>
            </div>

            <div className="flex flex-row gap-md">
                <div className="flex flex-row gap-md">
                    <p className="font-bold">
                        Gender at birth
                    </p>
                    <RadioGroup
                        options={[
                            {name: "genderAtBirth", value: "Male", label: "Male"},
                            {name: "genderAtBirth", value: "Female", label: "Female"}
                        ]}
                        onChange={handleGenderAtBirth}
                    />
                </div>
            </div>

            <div className="flex flex-row gap-md">
                <p className="font-bold">
                    Height (in cm)
                </p>
                <div className="text-p1 flex items-center mb-xs">
                    <input
                        className={"border border-solid border-primary-text rounded-md py-sm px-sm w-full max-w-full"}
                        type="number"
                        id="height"
                        name="height"
                        min={1}
                        max={300}
                        onChange={handleNumberChange}
                    />
                </div>
            </div>

            <div className="flex flex-row gap-md">
                <p className="font-bold">
                    Weight (in kg)
                </p>
                <div className="text-p1 flex items-center mb-xs">
                    <input
                        className={"border border-solid border-primary-text rounded-md py-sm px-sm w-full max-w-full"}
                        type="number"
                        id="weight"
                        name="weight"
                        min={1}
                        max={500}
                        onChange={handleNumberChange}
                    />
                </div>
            </div>

            <div className="flex flex-row gap-md">
                <div className="flex flex-row gap-md">
                    <p className="font-bold">
                        Diabetes status
                    </p>
                    <RadioGroup
                        options={[
                            {name: "diabetesStatus", value: "None", label: "None"},
                            {name: "diabetesStatus", value: "Type1", label: "Type 1"},
                            {name: "diabetesStatus", value: "Type2", label: "Type 2"}
                        ]}
                        onChange={handleDiabetesStatus}
                    />
                </div>
            </div>

            <div className="flex flex-row gap-md">
                <div className="flex flex-row gap-md">
                    <p className="font-bold">
                        Use of blood pressure medication
                    </p>
                    <RadioGroup
                        options={[
                            {name: "usesBloodPressureMedication", value: "true", label: "Yes"},
                            {name: "usesBloodPressureMedication", value: "false", label: "No"},
                        ]}
                        onChange={handleUsesBloodPressureMedication}
                    />
                </div>
            </div>

            <div className="flex flex-row gap-md">
                <div className="flex flex-row gap-md">
                    <p className="font-bold">
                        Are you a smoker?
                    </p>
                    <RadioGroup
                        options={[
                            {name: "isSmoker", value: "true", label: "Yes"},
                            {name: "isSmoker", value: "false", label: "No"},
                        ]}
                        onChange={handleIsSmoker}
                    />
                </div>
            </div>
            <div className="flex mb-2xl gap-md">
                {
                    <StateButton
                        loading={submitLoading}
                        submit={true}
                        disabled={submitButtonDisabled()}
                    >
                        Submit
                    </StateButton>
                }
            </div>

        </form>
    );
}

export default Home;